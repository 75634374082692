<template>
  <div class="rounder mb-2 mt-6 order order_content">
    <v-row class="mx-1">
      <v-col md="5" cols="12"></v-col>
      <v-col md="2" cols="3" class="text-right">Cijena</v-col>
      <v-col md="1" cols="3" class="text-right">Količina</v-col>
      <v-col md="2" cols="3" class="text-center"></v-col>
      <v-col md="2" cols="3" class="text-right">Ukupno</v-col>
    </v-row>
    <div
      v-for="(prod, index) in products"
      class="product_holder"
      :key="prod.id + '_' + index"
    >
      <v-row>
        <v-col md="5" cols="12" class="ubundu_b">{{ prod.title }}</v-col>
        <v-col md="2" cols="3" class="text-right"
          >{{ prod.parent_price | currency }}/{{ prod.jm }}</v-col
        >
        <v-col md="1" cols="3" class="text-right">{{ prod.quantity }}</v-col>
        <v-col md="2" cols="3" class="text-center">
          <v-btn small text @click="openNapomenaModal(prod)">
            <v-icon small class="ml-3"
              >{{
                prod.napomena == '' || prod.napomena == null ? 'far' : 'fas'
              }}
              fa-comment-alt</v-icon
            ></v-btn
          >
        </v-col>
        <v-col md="2" cols="3" class="text-right">{{
          (prod.quantity * prod.parent_price) | currency
        }}</v-col>
      </v-row>
    </div>
    <v-divider />
    <div class="bottom_order">
      <b>Očekivan iznos</b><span>{{ total | currency }}</span>
    </div>
    <v-divider />
    <div class="bottom_actions">
      <v-row>
        <v-col col-3 md-6>
          <v-btn
            small
            text
            @click="openNapomenaOrderaModal()"
            class="float-left"
          >
            <v-icon small>
              {{
                order_napomena == '' || order_napomena == null ? 'far' : 'fas'
              }}
              fa-comment-alt
            </v-icon>
          </v-btn>
        </v-col>
        <v-col col-4 md-6 v-if="userCredentials.kosarice">
          <v-btn
            small
            text
            class="float-right"
            style="padding:0; margin:0"
            @click="openSaveCartModal()"
          >
            Spremite košaricu
          </v-btn>
        </v-col>
        <v-col col-5 md-12>
          <v-btn
            small
            color="secondary"
            class="float-right"
            style="padding:0 20px"
            @click="send_checkout()"
          >
            Potvrdi narudžbu
            <v-icon small class="ml-3">fas fa-arrow-right</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </div>
    <v-dialog v-model="NapomenaModal" max-width="320">
      <v-card>
        <v-card-title class="headline">
          Napomena
          <v-icon small color="gray" @click="closeNapomenaModal()"
            >fas fa-times</v-icon
          >
        </v-card-title>
        <v-card-text max-height="400">
          <div class="napomene">
            <v-btn
              color="green"
              @click="note_add(note_o, 'np')"
              v-for="(note_o, index) in product_notes"
              :key="index"
              >{{ note_o }}</v-btn
            >
          </div>
          <v-textarea
            outlined
            v-model="napomena"
            label="Napomena"
            counter="255"
            maxlength="255"
            ref="np"
          ></v-textarea>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="primer" @click="closeNapomenaModal()">
            Spremi napomenu
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="NapomenaOrderaModal" max-width="320">
      <v-card>
        <v-card-title class="headline">
          Napomena naružbe
          <v-icon small color="gray" @click="closeNapomenaOrderaModal()"
            >fas fa-times</v-icon
          >
        </v-card-title>
        <v-card-text max-height="400">
          <div class="napomene">
            <v-btn
              color="green"
              class="text-white"
              @click="note_add(note_o, 'no')"
              v-for="(note_o, index) in order_notes"
              :key="index"
              >{{ note_o }}</v-btn
            >
          </div>
          <v-textarea
            outlined
            v-model="order_napomena"
            label="Napomena"
            counter="255"
            maxlength="255"
            ref="no"
          ></v-textarea>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="primer" @click="saveNapomenaOrderaModal()">
            Spremi napomenu
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="SaveCartModal" max-width="320">
      <v-card>
        <v-card-title class="headline">
          Nova košarica
          <v-icon small color="gray" @click="closeSaveCartModal()"
            >fas fa-times</v-icon
          >
        </v-card-title>
        <v-card-text max-height="300">
          <v-text-field
            v-model="name"
            :error-messages="nameErrors"
            label="Ime košarice"
            required
            v-eager
            maxlength="80"
            counter="80"
            solo
            @input="$v.name.$touch()"
            @blur="$v.name.$touch()"
          ></v-text-field>
          <v-select
                v-if="user_ids.includes(user_id)"
                v-model="poslovnica"
                :items="filteredPoslovnice"
                :search-input.sync="searchPoslovnice"
                item-text="title"
                item-value="id"
                label="Narudžba za poslovnicu"
                dense
                solo
          >
            <template v-slot:prepend-item>
              <v-list-item>
                <v-list-item-content>
                  <v-text-field
                    v-model="searchPoslovnice"
                    placeholder="Pretrazi..."
                    solo
                    clearable
                    @click.stop
                  ></v-text-field>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-select>
          <template v-if="user_ids.includes(user_id)">
            <v-checkbox
              v-for="day in daysOfWeek"
              :key="day.value"
              :label="day.label"
              :value="day.value"
              v-model="selectedDays"
            ></v-checkbox>
          </template>
        </v-card-text>
        <v-card-actions>
          <v-btn class="primer" @click="save_cart()" v-if="user_ids.includes(user_id)">
            Spremi kao novu
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn class="primer" @click="resave_cart()" v-if="user_ids.includes(user_id)">
            Spremi košaricu
          </v-btn>
          <v-btn class="primer" @click="save_cart()" v-if="!user_ids.includes(user_id)">
            Spremi košaricu
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { validationMixin } from 'vuelidate';
import { helpers, required, maxLength } from 'vuelidate/lib/validators';
const character_map = helpers.regex(
  'character_map',
  /^[a-zA-Z0-9Ć-ćČ-čĐ-đŠ-šŽ-žĆ-ćČ-čĐ-đŠ-šŽ-ž\-_':;.,()!?+ \s]*$/
);
export default {
  data() {
    return {
      user_ids: [47],
      NapomenaModal: false,
      NapomenaOrderaModal: false,
      SaveCartModal: false,
      item: {},
      name: '',
      searchPoslovnice: '',
      poslovnica: null,
      daysOfWeek: [
        { label: 'Pon.', value: '2' },
        { label: 'Uto.', value: '3' },
        { label: 'Sri.', value: '4' },
        { label: 'Čet.', value: '5' },
        { label: 'Pet.', value: '6' },
        { label: 'Sub.', value: '7' },
        { label: 'Ned.', value: '1' },
      ],
    };
  },
  watch: {
    selectedDays(newVal) {
      console.log('Selected days updated:', newVal);
      this.forceUpdate();
    }
  },
  computed: {
    ...mapGetters({
      products: 'cart/cartProducts',
      cart_id: 'cart/getCartId',
      cart_name: 'cart/getCartName',
      cart_napomena: 'cart/getCartNapomena',
      cart_poslovnica: 'cart/getCartPoslovnica',
      total: 'cart/cartTotalPrice',
      order: 'cart/getOrder',
      poslovnice: 'users/getPoslovnice',
      user_id: 'user/userId',
      order_notes: 'cart/getOrderNotes',
      product_notes: 'cart/getProductNotes',
      userCredentials: 'user/getCredentials',
    }),
    nameErrors() {
      const errors = [];
      if (!this.$v.name.$dirty) return errors;
      !this.$v.name.maxLength && errors.push('Maksimum 80 znakova');
      !this.$v.name.character_map &&
        errors.push('Naziv zadrži nedozvoljeni znak');
      !this.$v.name.required && errors.push('Ime košarice je obavezano');
      return errors;
    },
    filteredPoslovnice () {
      return this.poslovnice.filter((item) => {
        return item.title!=null && item.title.toLowerCase().includes(this.searchPoslovnice.toLowerCase())
      })
    },
    napomena: {
      set(napomena) {
        if (napomena.length > 254) {
          this.$store.commit('message_modal', {
            title: 'Greška',
            text: 'Maksimum 255 znakova',
          });
        } else {
          this.$store.commit('cart/setNapomena', {
            napomena,
            id: this.item.id,
          });
        }
      },
      get() {
        let id = this.item.id;
        let i =
          this.$store.getters['cart/getCartItems'].find((i) => i.id === id) ||
          {};
        let napomena =
          typeof i.napomena === 'undefined' || i.napomena === null
            ? ''
            : i.napomena;
        return napomena;
      },
    },
    selectedDays: {
      set(selectedDays) {
        this.$store.commit('cart/setSelectedDays', selectedDays);
      },
      get() {
        return this.$store.getters['cart/getCartDays'];
      },
    },
    order_napomena: {
      set(order_napomena) {
        if (order_napomena.length > 254) {
          this.$store.commit('message_modal', {
            title: 'Greška',
            text: 'Maksimum 255 znakova',
          });
        } else {
          this.$store.commit('cart/setOrderNapomena', {
            napomena: order_napomena,
            no: 6,
          });
        }
      },
      get() {
        return this.$store.getters['cart/getOrderNapomena'];
      },
    },
  },
  mixins: [validationMixin],
  validations: {
    name: {
      required,
      character_map,
      maxLength: maxLength(80),
    },
  },
  methods: {
    forceUpdate() {
      this.$forceUpdate();
    },
    send_checkout() {
      this.$store.dispatch('cart/send_checkout', this.order_napomena);
    },
    save_cart() {
      if (!this.$v.name.$error) {
        let cart_data={
          name: this.name,
          napomena: this.order_napomena,
          company: this.poslovnica,
          days: this.selectedDays.join(',')
        }
        console.log(cart_data);
        this.$store.dispatch('cart/save_cart', cart_data);
        this.SaveCartModal = false;
      }
    },
    resave_cart() {
      if (!this.$v.name.$error) {
        let cart_data={
          id: this.cart_id,
          name: this.name,
          napomena: this.order_napomena,
          company: this.poslovnica,
          days: this.selectedDays.join(',')
        }
        console.log(cart_data);
        this.$store.dispatch('cart/save_cart', cart_data);
        this.SaveCartModal = false;
      }
    },
    note_add(note_o, ref) {
      let note =
        ref === 'no'
          ? this.$refs.no.$el.querySelector('textarea')
          : this.$refs.np.$el.querySelector('textarea');
      var startPos = note.selectionStart,
        endPos = note.selectionEnd,
        cursorPos = startPos,
        tmpStr = note.value;
      // insert:
      let txtContent =
        tmpStr.substring(0, startPos) +
        ' ' +
        note_o +
        ' ' +
        tmpStr.substring(endPos, tmpStr.length);
      if (ref === 'no') {
        this.order_napomena = txtContent;
      } else {
        this.napomena = txtContent;
      }
      // move cursor:
      setTimeout(() => {
        cursorPos += note_o.length;
        note.selectionStart = note.selectionEnd = cursorPos;
      }, 10);
    },
    openNapomenaModal(item) {
      this.$store.dispatch('cart/get_product_notes', item);
      this.NapomenaModal = true;
      this.item = item;
    },
    closeNapomenaModal() {
      this.NapomenaModal = false;
    },
    openNapomenaOrderaModal() {
      this.$store.dispatch('cart/get_order_notes');
      this.NapomenaOrderaModal = true;
    },
    openSaveCartModal() {
      this.SaveCartModal = true;
    },
    closeNapomenaOrderaModal() {
      this.NapomenaOrderaModal = false;
    },
    closeSaveCartModal() {
      this.SaveCartModal = false;
    },
    saveNapomenaOrderaModal() {
      this.NapomenaOrderaModal = false;
    },
  },
  created() {
      if (this.$store.getters['user/getCredentials'].narucivanje == 0)
      this.$router.push({ name: 'not_auth' });
      this.poslovnica = this.cart_poslovnica;
      this.name = this.cart_name;
      this.order_napomena = this.cart_napomena??'';
  },
};
</script>
<style scoped>
.headline {
  position: relative;
}
.headline button {
  position: absolute;
  right: 10px;
  top: 10px;
  font-size: 18px;
}
.napomene {
  overflow-y: auto;
  height: 210px;
}
.order {
  border: solid 1px rgba(0, 0, 0, 0.2);
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.07);
  background: #fff;
}
.order_status {
  position: absolute;
  right: 60px;
  top: 0;
  height: 60px;
  line-height: 60px;
}
.order_header {
  width: 100%;
  height: 60px;
  min-height: 60px !important;
  line-height: 60px;
  position: relative;
  padding: 10px 10px 10px 60px !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.03);
}
.product_holder,
.package_holder,
.bottom_order {
  position: relative;
  width: 100%;
  min-height: 80px;
  line-height: 20px;
  padding: 10px 10px 10px 10px;
  border-top: 1px solid rgb(226, 226, 226);
}
.package_holder .product_holder {
  border: none;
  padding: 0;
  color: rgb(143, 143, 143);
}
.bottom_order {
  position: relative;
  height: 60px;
  padding-top: 15px;
}
.product_img {
  position: absolute;
  left: 0px;
  top: 5px;
  width: 70px;
  height: 70px;
}
.product_title {
  min-height: 20px;
  line-height: 20px;
  position: relative;
}
.bottom_order span {
  position: absolute;
  right: 10px;
  text-align: right;
  display: block;
  top: 10px;
  line-height: 20px;
}
.blur {
  position: relative;
}
.blur::after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.5);
}
.gray_cycle {
  position: absolute;
  left: 18px;
  top: 18px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: #f7f7f7;
}
.item_icon,
.bottom_order_icon {
  position: absolute;
  left: 4px;
  top: 4px;
  width: 16px;
  height: 16px;
}
.bottom_order_icon {
  left: 24px;
  top: 21px;
  width: 24px;
  height: 24px;
}
.top {
  bottom: unset;
  top: 10px;
}
.bottom_actions {
  width: 100%;
  padding: 0 20px;
  min-height: 70px;
  line-height: 70px;
}</style
>>
